<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="mb-8 d-flex align-center">
          <span class="title font-weight-bold" label>2.3</span>
          <v-icon class="mx-2">mdi-circle-small</v-icon>
          <div class="subtitle-2">Assignment File Config list</div>
          <v-spacer></v-spacer>
        </div>

        <v-dialog v-model="configfiledialog">
          <v-card class="">
            <v-card-text class="primary white--text d-flex align-center py-2 px-4">
              <span class="subtitle-1 white--text">Configure '{{filedata.name}}' File</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="configfiledialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-text>
            <v-card-text class="mt-4 pt-4">
              <fileconfig v-if="configfiledialog" :id="configfileid" :config="fileconfig[configfileid]" :filedata="filedata"/>
            </v-card-text>
          </v-card>
        </v-dialog>

        <!-- <v-slide-y-transition group> -->
          <div v-for="(v, k) in filelist" :key="k" class="px-2 py-1 hoverable cursor-pointer d-flex align-center view-on-hover-area" v-ripple>
            <div class="subtitle-2 my-1">
              <v-icon left>mdi-file-excel-outline</v-icon>
              <span>{{v.name}}</span>
            </div>
            <span v-if="configuredFileIds.indexOf(v._id) > -1" outlined class="success--text ml-4" small><v-icon class="success--text">mdi-check-circle-outline</v-icon> Configured</span>
            <span v-else class="warning--text ml-4" outlined small><v-icon class="warning--text">mdi-exclamation</v-icon> Not configured</span>
            <v-btn icon small class="ml-4 view-on-hover-item" @click="configfileid=v._id;configfiledialog=true;filedata=v"><v-icon>mdi-cog</v-icon></v-btn>
            <v-spacer></v-spacer>

          </div>
       

        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn  color="primary" elevation="0" small @click="confirmConfiguration()">Confirm Configuration</v-btn>
        </div>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import fileconfig from '../Config/ConfigureFileDo';

export default {
  name: 'engagement_setup_fileconfig',
  data: function(){
    return {
      loading: false,
      configfiledialog: false,
      filelist: [],
      filedata: {},
      fileconfig: {},
      configuredFileIds: [],
      configfileid: "",
      error: "",
    }
  },
  components: {
    fileconfig
   // pageHeader,managelist
  },
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = false;
      this.configfiledialog = false;
      this.filelist = [];
      this.fileconfig = {};
      this.configuredFileIds = [];
      this.configfileid = "";
      this.error = "";
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/config/getfilelist").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.filelist = dt.data.data;
          let fileids = []
          for (const j of this.filelist) {
            fileids.push(j._id);
          }
          return this.axios.post("/v2/ia/config/getfileconfig", {data: fileids});
        }else throw new Error ("No scope details found");
      }).then(dt=> {
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){

            for (const i of dt.data.data) {
               this.fileconfig[i.file] = i;
            }
            this.configuredFileIds = Object.keys(this.fileconfig);

          }
        }else throw new Error ("Error fething file config details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    confirmConfiguration(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/confirmfileconfig/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.refreshData();
        }else throw new Error (dt.data.message || "Error processing request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
  },
}
</script>
