<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="mb-8 d-flex align-center">
          <span class="title font-weight-bold" label>2.4</span>
          <v-icon class="mx-2">mdi-circle-small</v-icon>
          <div class="subtitle-2">Assignment Input Parameter Config list</div>
          <v-spacer></v-spacer>
          <v-btn  color="primary" elevation="0" small @click="confirmConfiguration()">Confirm Configuration</v-btn>
        </div>
        <div class="d-flex py-2 align-center">
          <v-spacer></v-spacer>
          <v-avatar size="15" color="info"></v-avatar>
          <span class="ml-2">Non Hidden Input Parameters</span>
        </div>
        <v-slide-y-transition group>
          <template v-for="(v, k) in iplist">
            <div :key="k" class="hoverable view-on-hover-area">
              <div class="d-flex align-top px-2 py-4">
                <div class="mr-4">
                  <div class="d-flex align-center">
                    <v-chip label text small :class="`${(v.hidden)?'':'info'}`">{{v.code}}</v-chip>
                    <v-icon class="mx-1">mdi-circle-small</v-icon>
                    <div class="subtitle-2 my-1">
                      <div>{{v.name}}</div>
                    </div>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <div style="width:30%">
                  <div v-if="v.type==='number'" class="parameter-input">
                    <lb-number v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='string'" class="parameter-input">
                    <lb-string v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='date'" class="parameter-input">
                    <lb-date v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='year'" class="parameter-input">
                    <lb-year v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='month'" class="parameter-input">
                    <lb-month v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='time'" class="parameter-input">
                    <lb-time v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='daterange'" class="parameter-input">
                    <lb-daterange v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="v.type==='timerange'" class="parameter-input">
                    <lb-timerange v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>
                  <div v-else-if="['numberarray','stringarray','datearray','yeararray','montharray','timearray','daterangearray','timerangearray'].indexOf(v.type) > -1" class="parameter-input">
                    <lb-list :type="v.type" display="list" v-model="inputparametersdata[v._id]" :error="inputparameterserror[v._id]" />
                  </div>

                </div>
              </div>
              <v-divider></v-divider>
            </div>
          </template>
        </v-slide-y-transition>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'engagement_setup_ipconfig',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      configfiledialog: false,
      iplist: [],
      ipconfig: {},
      configuredFileIds: [],
      inputparametersdata: {},
      inputparameterserror: {},
      error: "",
    }
  },
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.loading = false;
      this.iplist = [];
      this.ipconfig = {};
      this.inputparametersdata = {};
      this.inputparameterserror = {};
      this.error = "";
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/config/getinputparameterlist").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.iplist = dt.data.data;
          let ipids = []
          for (const i of this.iplist) {
            ipids.push(i._id);
            if(i.value) this.inputparametersdata[i._id] = i.value;
          }
          return this.axios.post("/v2/ia/config/getinputparameterconfig", {data: ipids});
        }else throw new Error ("No scope details found");
      }).then(dt=> {
        if(dt.data.status === "success"){
          if(dt.data.data.length > 0){

            for (const k of dt.data.data) {
              this.ipconfig[k.parameter] = k.value;
              console.log(k.parameter, k.value);
              this.inputparametersdata[k.parameter] = k.value;
            }
          }
        }else throw new Error ("Error fething input parameter config details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    confirmConfiguration(){
      this.error = "";
      this.loading = true;
      let data = [];
      for (const k in this.inputparametersdata) {
        if (Object.hasOwnProperty.call(this.inputparametersdata, k)) {
          const el = this.inputparametersdata[k];
          data.push({parameter: k, value: el});
        }
      }
      this.axios.post("/v2/ia/config/addinputparameter", {data: data}).then(dt => {
        if(dt.data.status === "success"){
          return this.axios.post("/v2/ia/assignment/confirminputparameterconfig/"+this.id);
        }else throw new Error (dt.data.message || "Error processing request");
      }).then(dt => {
        if(dt.data.status === "success"){
          this.refreshData();
        }else throw new Error (dt.data.message || "Error processing request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
    // "$store.state.client": function(){
    //   this.refreshData();
    // },
  }
}
</script>
