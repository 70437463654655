<template>
  <div v-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}
    </v-alert>
  </div>
  <div v-else class="all-width">
    <v-card loader-height="2" :loading="loading" :disabled="loading">
      <v-card-text>
        <div class="mb-8 d-flex align-center">
          <span class="title font-weight-bold" label>2.2</span>
          <v-icon class="mx-2">mdi-circle-small</v-icon>
          <div class="subtitle-2">Master Contact List to define the Process & Information Owners</div>
          <v-spacer></v-spacer>
        </div>

        <div class="subtitle-1 border-left-primary pl-2 mb-2">Process Owners</div>
        <div v-for="(v, k) in arealist" :key="k" class="">
          <div class="d-flex align-center hoverable px-2 py-2">
            <div class="subtitle-2 my-1">
              <v-icon left>mdi-file-excel-outline</v-icon>
              <span>{{v.name}}</span>
            </div>
            <v-spacer></v-spacer>
            <lb-string style="max-width:300px" class="mx-2" hidedetails label="Name"></lb-string>
            <lb-number style="max-width:250px" class="mx-2" hidedetails label="Contact Number"></lb-number>
          </div>
        </div>

        <div class="subtitle-1 border-left-primary pl-2 mt-10 mb-2">Information Owners</div>
        <div v-for="(v, k) in filelist" :key="k" class="">
          <div class="d-flex align-center hoverable px-2 py-1">
            <div class="subtitle-2 my-1">
              <v-icon left>mdi-file-excel-outline</v-icon>
              <span>{{v.name}}</span>
            </div>
            <v-spacer></v-spacer>
            <lb-string style="max-width:300px" class="mx-2" hidedetails label="Name"></lb-string>
            <lb-number style="max-width:250px" class="mx-2" hidedetails label="Contact Number"></lb-number>
          </div>
        </div>

        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-btn  color="primary" elevation="0" small @click="confirmConfiguration()">Confirm Contact List</v-btn>
        </div>

      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'

export default {
  name: 'engagement_setup_contactlist',
  // props: ['props'],
  data: function(){
    return {
      loading: false,
      configfiledialog: false,
      filelist: [],
      arealist: [],
      filedata: {},
      fileconfig: {},
      configuredFileIds: [],
      configfileid: "",
      error: "",
    }
  },
  components: {
   // pageHeader,managelist
  },
  props: {
    id: {
      type: String,
      default: '',
    }
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
 

  methods: {
    refreshData() {
      this.loading = false;
      this.filelist = [];
      this.arealist = [];
      this.error = "";
      this.getData();
    },
    getData(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/config/getfilelist").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.filelist = dt.data.data;
          return this.axios.post("/v2/ia/engagement/scopelist");
        }else throw new Error ("No scope details found");
      }).then(dt=> {
        if(dt.data.status === "success"){
          this.arealist = (dt.data.data[0] || {}).area || [];
        }else throw new Error ("Error fething file config details");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    confirmConfiguration(){
      this.error = "";
      this.loading = true;
      this.axios.post("/v2/ia/assignment/confirmfileconfig/"+this.id).then(dt => {
        if(dt.data.status === "success"){
          this.refreshData();
        }else throw new Error (dt.data.message || "Error processing request");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  computed: {
  },
  watch: {
    // "$store.state.client": function(){
    //   this.refreshData();
    // },
  }
}
</script>
