<template>
  <div v-if="pageloading">
    <v-skeleton-loader type="card-heading, list-item-three-line"></v-skeleton-loader>
  </div>
  <div v-else-if="error" class="full-height">
    <v-alert type="error" border="left" text :value="true">
      {{error}}      
    </v-alert>
  </div>
  <div v-else class="full-height d-flex vertical-setup">
      <div class="tabs position-sticky">
        <v-btn class="shadow-off mt-4" text @click="$nova.gotoLink({path: '/ia/engagement/view/'+id})"><v-icon left>mdi-arrow-left</v-icon> Back to progress</v-btn>
        <div class="head">Client setup</div>
        <v-btn :class="{'link': true, 'selected': tab === 'Risk Info', 'shadow-off': tab !== 'Risk Info'}" text @click="$nova.gotoLink({hash: '#risk'})">
          <v-icon v-if="status.risk" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          Risk Info
        </v-btn>
        <v-btn :class="{'link': true, 'selected': tab === 'Multi Location', 'shadow-off': tab !== 'Multi Location'}" text @click="$nova.gotoLink({hash: '#location'})">
          <v-icon v-if="status.location || !riskdata.multilocation" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          Multi Location
        </v-btn>
        <div class="head">Engagement Setup</div>
        <v-btn :class="{'link': true, 'selected': tab === 'Scope Mapping', 'shadow-off': tab !== 'Scope Mapping'}" text @click="$nova.gotoLink({hash: '#scope'})">
          <v-icon v-if="status.scope" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          Scope Mapping
        </v-btn>
        <v-btn :class="{'link': true, 'selected': tab === 'Contact List', 'shadow-off': tab !== 'Contact List'}" text @click="$nova.gotoLink({hash: '#contacts'})">
          <v-icon v-if="status.contact" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          Contact List
        </v-btn>
        <v-btn :class="{'link': true, 'selected': tab === 'File Config', 'shadow-off': tab !== 'File Config'}" text @click="$nova.gotoLink({hash: '#fileconfig'})">
          <v-icon v-if="status.scope" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          File Config
        </v-btn>
        <v-btn :class="{'link': true, 'selected': tab === 'Input Parameter Config', 'shadow-off': tab !== 'Input Parameter Config'}" text @click="$nova.gotoLink({hash: '#inputparameterconfig'})">
          <v-icon v-if="status.scope" left color="success">mdi-check-circle-outline</v-icon>
          <v-icon v-else left color="info">mdi-timer-sand</v-icon>
          Input Parameter Config
        </v-btn>
      </div>
      <div class="content-window">
        <v-scroll-y-transition group>
          <div v-if="tab === 'Risk Info'" key="1" class="content-box">
            <v-card class="mb-4" :loader-height="2" :loading="loading" :disabled="loading">
              <v-card-text class="">
                  <div class="mb-8 d-flex align-center">
                    <span class="title font-weight-bold" label>1.1</span>
                    <v-icon class="mx-2">mdi-circle-small</v-icon>
                    <div class="subtitle-2">Basic Engagement Information</div>
                  </div>
                  <lb-string label="Name of the Client" class="" :value="$nova.clientName()" disabled></lb-string>
                  <v-row class="my-0 py-0">
                      <v-col cols="8" class="my-0 py-1">
                        <lb-dropdown :items="sectors" label="Sector" v-model="riskdata.sector" :error="riskerrors['sector']" itemtext="name" itemvalue="_id"></lb-dropdown>
                      </v-col>
                      <v-col cols="4" class="my-0 py-1">
                        <lb-string label="Project Code" v-model="riskdata.projectcode" :error="riskerrors['projectcode']"></lb-string>
                      </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                      <v-col cols="12" class="my-0 py-1">
                        <lb-file label="Upload Engagement Letter" v-model="engagementletter" :error="riskerrors['file']" :id="riskdata.file"></lb-file>
                      </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                      <v-col cols="4" class="my-0 py-1">
                        <lb-dropdown :items="partners" label="Engagement Partner" v-model="riskdata.partner" :error="riskerrors['partner']" itemtext="name" itemvalue="_id"></lb-dropdown>
                      </v-col>
                      <v-col cols="4" class="my-0 py-1">
                        <lb-dropdown :items="managers" outlined dense label="Engagement Manager" v-model="riskdata.manager" :error="riskerrors['manager']" itemtext="name" itemvalue="_id"></lb-dropdown>
                      </v-col>
                      <v-col cols="4" class="my-0 py-1">
                        <lb-dropdown :items="qualitypartners" outlined dense label="QC Partner" v-model="riskdata.qualitypartner" :error="riskerrors['qualitypartner']" itemtext="name" itemvalue="_id"></lb-dropdown>
                      </v-col>
                  </v-row>
                  <v-row class="my-0 py-0">
                      <v-col cols="6" class="my-0 py-1">
                          <lb-number label="Fee in INR" v-model="riskdata.fees" :error="riskerrors['fees']"></lb-number>
                      </v-col>
                      <v-col cols="6" class="my-0 py-1">
                        <v-checkbox :value="true" label="Multi Location" class="mt-1" hide-details v-model="riskdata.multilocation" :error="riskerrors['multilocation']"></v-checkbox>
                      </v-col>
                  </v-row>
                  <div class="mt-4">
                    <strong>Note:</strong> Project Code is required for timesheet linking
                  </div>

                  <div class="text-right pt-4">
                      <v-btn color="primary" elevation="0" @click="updateRisk()" small>Save Details</v-btn>
                  </div>
              </v-card-text>
            </v-card>
          </div>
          <div v-else-if="tab === 'Multi Location'" key="2" class="content-box">
              <v-card class="mb-4" :loader-height="2" :loading="loading" :disabled="loading">
                <v-card-text class="">
                  <div class="mb-8 d-flex align-center">
                    <span class="title font-weight-bold" label>1.2</span>
                    <v-icon class="mx-2">mdi-circle-small</v-icon>
                    <div class="subtitle-2">Multiple location Information</div>
                  </div>
                  <div v-if="!riskdata.multilocation" >
                    <v-alert border="left" text type="info" :value="true">Multi location is disabled in the "Risk Info" stage. Please enable the same to add multi location information</v-alert>
                    <v-btn color="primary" elevation="0" class="mb-4" small @click="tab='Risk Info'">Goto Risk Info</v-btn>
                  </div>
                  <div v-else>

                    <v-dialog v-model="locationdialog" max-width="600">
                      <v-card :loading="locationloading" :disabled="locationloading" loader-height="2" class="">
                        <v-card-text class="primary white--text d-flex align-center py-2 px-4">
                          <span class="subtitle-1 white--text">Add New Location</span>
                          <v-spacer></v-spacer>
                          <v-btn icon @click="locationdialog = false" class="ml-2 white--text"><v-icon>mdi-close</v-icon></v-btn>
                        </v-card-text>
                        <v-card-text class="mt-4 pt-4">
                          <v-row>
                            <v-col cols="12" class="my-0 py-1">
                              <lb-string v-model="addlocationdata.name" label="Location Name" :error="addlocationdataerror.name"/>
                            </v-col>
                            <v-col cols="6" class="my-0 py-1">
                              <lb-dropdown :items="['Branch','Subsidiary','Associate']" v-model="addlocationdata.type" label="Location Type"  :error="addlocationdataerror.type"/>
                            </v-col>
                            <v-col cols="6" class="my-0 py-1">
                              <lb-string v-model="addlocationdata.contactperson" label="Contact Person" :error="addlocationdataerror.contactperson"/>
                            </v-col>
                            <v-col cols="6" class="my-0 py-1">
                              <lb-number v-model="addlocationdata.contactno" label="Contact Number" :error="addlocationdataerror.contactno"/>
                            </v-col>
                            <v-col cols="6" class="my-0 py-1">
                              <lb-string v-model="addlocationdata.contactemail" label="Contact Email" :error="addlocationdataerror.contactemail"/>
                            </v-col>
                          </v-row>
                        </v-card-text>
                        <v-card-text class="d-flex">
                          <v-spacer></v-spacer>
                          <v-btn small color="primary" @click="addNewLocation()">Add Location</v-btn>
                        </v-card-text>
                      </v-card>
                    </v-dialog>

                    <v-btn color="primary" elevation="0" class="mb-4" small @click="locationdialog = true;">Add Location</v-btn>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr class="grey lighten-3">
                            <th scope="#" class=""></th>
                            <th scope="location-name" class="text-left">Location Name</th>
                            <th scope="type" class="text-left">Type</th>

                            <th scope="Contact Person" class="text-left">Contact Person</th>
                            <th scope="contact-number" class="text-left">Contact Number</th>
                            <th scope="contact-email" class="text-left">Contact email</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(v,k) in locations" :key="k">
                            <td style="width:40px;" class="px-2 text-center"><v-checkbox class="mb-2" dense hide-details v-model="selectedlocation" :value="v._id"></v-checkbox> </td>
                            <td>{{v.name}}</td>
                            <td>{{v.type}}</td>
                         
                            <td>{{v.contactperson}}</td>
                            <td>{{v.contactno}}</td>
                            <td>{{v.contactemail}}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>

                    <div class="d-flex pt-4">
                      <span v-if="selectedlocation.length === 0" class="red--text">Select atleast 1 location!!</span>
                      <v-spacer></v-spacer>
                      <v-btn v-if="selectedlocation.length > 0" color="primary" elevation="0" small @click="updateLocation()">Save Details</v-btn>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
          </div>
          <div v-else-if="tab === 'Scope Mapping'" key="3" class="content-box">
            <v-card class="mb-4" :loader-height="2" :loading="loading" :disabled="loading">
                <v-card-text class="">
                  <div class="mb-8 d-flex align-center">
                    <span class="title font-weight-bold" label>2.1</span>
                    <v-icon class="mx-2">mdi-circle-small</v-icon>
                    <div class="subtitle-2">Select the applicable scope for the engagement</div>
                  </div>
                  


                  <div class="">
                    <div v-for="(v,k) in scopelist" :key="k" class="d-flex align-center">
                      <v-checkbox class="my-0 py-1" hide-details dense :label="v.name" v-model="selectedscope" :value="v._id">
                      </v-checkbox>
                      <span v-if="updatescopeerror[v._id]" class="error--text ml-4">Error: {{updatescopeerror[v._id]}}</span>
                    </div>
                  </div>
                  <div class="d-flex pt-4">
                    <span v-if="selectedscope.length === 0" class="red--text">Select atleast 1 scope!!</span>
                    <v-spacer></v-spacer>
                    <v-btn v-if="selectedscope.length > 0" color="primary" elevation="0" @click="updateScope()">Save Details</v-btn>
                  </div>
                  <div class="text-right pt-4">
                  </div>
                </v-card-text>
            </v-card>
          </div>
          <div v-else-if="tab === 'File Config'" key="4" class="content-box">
            <filelist/>
          </div>
          <div v-else-if="tab === 'Input Parameter Config'" key="5" class="content-box">
            <inputparameterlist/>
          </div>
          <div v-else-if="tab === 'Contact List'" key="6" class="content-box">
            <contactlist/>
          </div>
        </v-scroll-y-transition>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import pageHeader from '@/components/pageHeader'
// import managelist from '@/components/ManageList'
import filelist from './FileList';
import inputparameterlist from './InputParameterList';
import contactlist from './ContactList';

export default {
  name: 'engagement_setup',
  // props: ['props'],
  data: function(){
    return {
      tab: "",
      id: '',
      loading: false,
      pageloading: false,
      error: "",
      engagementletter: null,
      riskdata: {},
      riskerrors: {},
      partners: [],
      qualitypartners: [],
      managers: [],
      sectors: [],
      locations: [],
      selectedlocation: [],
      addlocationdata: {},
      addlocationdataerror: {},
      locationdialog: false,
      locationloading: false,
      updatelocationerror: {},
      scopelist: [],
      selectedscope: [],
      updatescopeerror: {},
      status: {risk: false, location: false, scope: false}
    }
  },
  components: {
    filelist, inputparameterlist, contactlist
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
      this.tab = "";
      this.id = '';
      this.loading = false;
      this.pageloading = false;
      this.error = "";
      this.engagementletter = null;
      this.riskdata = {};
      this.riskerrors = {};
      this.partners = [];
      this.qualitypartners = [];
      this.managers = [];
      this.sectors = [];
      this.locations = [];
      this.selectedlocation = [];
      this.addlocationdata = {};
      this.addlocationdataerror = {};
      this.locationdialog = false;
      this.locationloading = false;
      this.updatelocationerror = {};
      this.scopelist = [];
      this.selectedscope = [];
      this.updatescopeerror = {};
      this.status = {risk: false, location: false, scope: false};
      this.id = this.$route.params.id || "";
      this.addlocationdata={};
      this.navigateHash();
      this.getData();
    },
    navigateHash(){
      if(this.$route.hash === "#location") this.tab = "Multi Location";
      else if(this.$route.hash === "#scope") this.tab = "Scope Mapping";
      else if(this.$route.hash === "#fileconfig") this.tab = "File Config";
      else if(this.$route.hash === "#inputparameterconfig") this.tab = "Input Parameter Config";
      else if(this.$route.hash === "#contacts") this.tab = "Contact List";
      else this.tab = "Risk Info"
    },
    getData(){
      this.errors = {};
      this.pageloading = true;
      this.axios.post("/v2/ia/engagement/setupformselects").then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let ndt = dt.data.data[0]
          
          this.partners = [...ndt.partner || []];
          this.qualitypartners = [...ndt.partner || []];
          this.managers = ndt.manager || [];
          this.sectors = ndt.sector || [];
          this.locations = ndt.location || [];
          this.scopelist = ndt.scopelist || [];
          if(this.locations.length > 0) this.selectedlocation.push(this.locations[0]._id);
          return this.axios.post("/v2/ia/engagement/getsetup/"+this.id);
        }else throw new Error ("Error loading the form details");
      }).then(dt=> {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          let engagementSetup = dt.data.data[0].engagement_setup ?? {};
          this.riskdata = engagementSetup.riskinfo ?? {};
          if(engagementSetup.riskinfo) this.status.risk = true;
          this.selectedlocation = engagementSetup.location ?? this.selectedlocation ?? [];
          if(engagementSetup.location) this.status.location = true;
          this.selectedscope = engagementSetup.scope ?? this.selectedscope ?? [];
          if(engagementSetup.scope) this.status.scope = true;
        }else {
          this.error = "No engagement found!!"
          throw new Error ("Error fething the engagement details");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.pageloading = false;
      })
    },
    updateRisk(){
      this.loading = true;
      let uploadFileFlag = true;
      this.riskerrors = {};
      Promise.resolve().then(() => {
        if(this.riskdata.file && !this.engagementletter) uploadFileFlag = false;
        if(uploadFileFlag) return this.$nova.uploadFile(this.axios, this.engagementletter);
      }).then(dt => {
        let proceedFlag = false;
        if(uploadFileFlag){
          if(dt.data.status === "success" && dt.data.data.length > 0){
            this.riskdata.file = dt.data.data[0]._id;
            this.engagementletter.id = dt.data.data[0]._id;
            proceedFlag = true;
          }else throw new Error ("Unable to upload file");
        }else proceedFlag = true;
        if(proceedFlag) return this.axios.post("/v2/ia/engagement/updaterisk", {data: this.riskdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Risk Info Updated");
          this.refreshData();
        }else{
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.riskerrors = ((dt.data.data[0].index0 || {}).riskinfo[0]).index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    updateLocation(){
      this.loading = true;
      this.updatelocationerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/updatelocation", {data: this.selectedlocation});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "Location Information Updated");
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if(dt.data.data[0].index0) this.updatelocationerror = dt.data.data[0].index0;
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    addNewLocation(){
      this.locationloading = true;
      this.addlocationdataerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/addnewlocation", {data: this.addlocationdata});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          this.$store.commit("sbSuccess", "New location added");
          this.locationdialog = false;
          this.refreshData();
        }else {
          if (dt.data.data.length > 0){
            if((dt.data.data[0].index0 ?? {}).location) {
              let errlist = dt.data.data[0].index0.location[0] ?? [];
              console.log(errlist);
              for (const k in errlist ?? []) {
                if (Object.hasOwnProperty.call(errlist, k)) {
                  let el = errlist[k];
                  console.log(k, el)
                  this.addlocationdataerror[this.selectedlocation[k.split("").pop()]] = el[0] || "";                
                }
              }
            }
          }
          throw new Error (dt.data.message || "Unknown error!");

        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.locationloading = false;
      })
    },
    updateScope(){
      this.loading = true;
      this.updatescopeerror = {};
      Promise.resolve().then(() => {
        return this.axios.post("/v2/ia/engagement/updatescope", {data: this.selectedscope});
      }).then(dt => {
        if(dt.data.status === "success" && dt.data.data.length > 0){
          console.log(dt.data);
          this.$store.commit("sbSuccess", "Scope Information Updated");
        }else {
          if (dt.data.data.length > 0){
            if((dt.data.data[0].index0 ?? {}).scope) {
              let errlist = dt.data.data[0].index0.scope[0] ?? [];
              console.log(errlist);
              for (const k in errlist ?? []) {
                if (Object.hasOwnProperty.call(errlist, k)) {
                  let el = errlist[k];
                  console.log(k, el)
                  this.updatescopeerror[this.selectedscope[k.split("").pop()]] = el[0] || "";                
                }
              }
            }
          }
          throw new Error (dt.data.message || "Unknown error!");
        }
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
  },
  watch: {
    "$store.state.client": function(){
      this.refreshData();
    },
    "$route.hash": function(){
      this.navigateHash();
    }
  }
}
</script>
